// Toast.js
import React, { useEffect } from 'react';

const Toast = ({ notified, message, setToast }) => {
  // Function to determine the auto-dismiss duration based on 'notified' type
  const getDismissTime = () => {
    switch (notified) {
      case "activate":
      case "deactivate":
      case "new alert error":
      case "update alert error":
      case "delete alert error":
        return 2000; // 2 seconds
      case "duplicate":
        return 3000; // 3 seconds
      case "new alert success":
      case "update alert success":
      case "delete alert success":
        return 5000; // 5 seconds
      default:
        return 5000; // Default to 5 seconds
    }
  };

  // Auto-dismiss the toast after the specified duration
  useEffect(() => {
    if (notified) {
      const timer = setTimeout(() => {
        setToast({ state: false, notified: "", message: "" });
      }, getDismissTime());

      return () => clearTimeout(timer);
    }
  }, [notified, setToast]);

  // Function to select the appropriate icon based on 'notified' type
  const getIcon = () => {
    switch (notified) {
      case "activate":
      case "deactivate":
      case "new alert success":
      case "update alert success":
      case "delete alert success":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            width={18}
            height={18}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "new alert error":
      case "update alert error":
      case "delete alert error":
      case "duplicate":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
          >
            <path
              d="M12 9V12.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 15.75H12.008V15.758H12V15.75Z"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            width={18}
            height={18}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
    }
  };

  // Function to get the default message based on 'notified' type
  const getDefaultMessage = () => {
    switch (notified) {
      case "activate":
        return "Activated alert successfully.";
      case "deactivate":
        return "Deactivated alert successfully.";
      case "new alert success":
        return "Created alert successfully. Manage it on the 'Alerts' page.";
      case "update alert success":
        return "Updated alert successfully.";
      case "delete alert success":
        return "Deleted alert successfully.";
      case "duplicate":
        return "Duplicate alerts detected! Please edit the alert before saving.";
      case "new alert error":
      case "update alert error":
      case "delete alert error":
        return "An error occurred while processing your request.";
      default:
        return "";
    }
  };

  return (
    <div
      id="toast-default"
      className={`flex absolute top-0 left-1/2 transform -translate-x-1/2 items-center w-full max-w-max p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
      role="alert"
    >
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
        {getIcon()}
        <span className="sr-only">Toast icon</span>
      </div>
      <div className="ml-3 text-sm font-normal">
        {message || getDefaultMessage()}
      </div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        aria-label="Close"
        onClick={() => {
          setToast({ state: false, notified: "", message: "" });
        }}
      >
        <span className="sr-only">Close</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
};

// Exported as default
export default Toast;


//// ORIGINAL CODE BELOW
// const Toast = ({ notified, setToast }) => {
//   // Disappear after customized seconds
//   const disappear = (seconds) => {
//     setTimeout(() => {
//       setToast({ state: false, notified: false });
//     }, seconds);
//   };

//   if (
//     notified === "activate" ||
//     notified === "deactivate" ||
//     notified === "alert error"
//   )
//     disappear(2000);
//   if (notified === "duplicate") disappear(3000);
//   if (notified === "alert success") disappear(5000);

//   return (
//     <div
//       id="toast-default"
//       className={`flex absolute top-0 left-1/2 transform -translate-x-1/2 items-center w-full max-w-max p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`}
//       role="alert"
//     >
//       <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200">
//         {notified === "deactivate" && (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth="1.5"
//             stroke="currentColor"
//             width={18}
//             height={18}
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M9.143 17.082a24.248 24.248 0 003.844.148m-3.844-.148a23.856 23.856 0 01-5.455-1.31 8.964 8.964 0 002.3-5.542m3.155 6.852a3 3 0 005.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 003.536-1.003A8.967 8.967 0 0118 9.75V9A6 6 0 006.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
//             />
//           </svg>
//         )}
//         {notified === "activate" && (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             strokeWidth="1.5"
//             stroke="currentColor"
//             width={18}
//             height={18}
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
//             />
//           </svg>
//         )}
//         {(notified === "duplicate" || notified === "alert error") && (
//           <svg
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//             stroke="currentColor"
//           >
//             <path
//               d="M12 9V12.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM12 15.75H12.008V15.758H12V15.75Z"
//               strokeWidth="1.5"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//             />
//           </svg>
//         )}
//         {notified === "alert success" && (
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 24 24"
//             width="24"
//             height="24"
//             strokeWidth="1.5"
//             stroke="currentColor"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//             />
//           </svg>
//         )}
//         <span className="sr-only">Fire icon</span>
//       </div>
//       <div className="ml-3 text-sm font-normal">
//         {notified === "deactivate" && "Deactivated the alert successfully"}
//         {notified === "activate" && "Activated the alert successfully"}
//         {notified === "duplicate" &&
//           "Duplicate alerts detected! Please edit the alert before saving"}
//         {notified === "alert success" &&
//           "Alert added successfully. Manage it on the 'Alerts' page"}
//         {notified === "alert error" && "Alert added failed. Please try again"}
//       </div>
//       <button
//         type="button"
//         className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
//         data-dismiss-target="#toast-default"
//         aria-label="Close"
//         onClick={() => {
//           if (
//             notified === "activate" ||
//             notified === "deactivate" ||
//             notified === "alert success" ||
//             notified === "alert error"
//           ) {
//             setToast({
//               state: false,
//               notified: "",
//             });
//           }
//           if (notified === "duplicate") {
//             setToast(false);
//           }
//         }}
//       >
//         <span className="sr-only">Close</span>
//         <svg
//           aria-hidden="true"
//           className="w-5 h-5"
//           fill="currentColor"
//           viewBox="0 0 20 20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             fillRule="evenodd"
//             d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//             clipRule="evenodd"
//           ></path>
//         </svg>
//       </button>
//     </div>
//   );
// };

// export default Toast;
