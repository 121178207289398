import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import API from '../Shared/API'; // Import the centralized API utility

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [score, setScore] = useState(0);

  // Update password quality whenever newPassword changes
  useEffect(() => {
    if (newPassword) {
      checkPasswordQuality(newPassword);
    } else {
      setScore(0); // Reset score if newPassword is empty
    }
  }, [newPassword]);

  // Validate password fields
  useEffect(() => {
    if (newPassword.length > 0 && newPassword.length < 8) {
      setError('The new password must be at least 8 characters long.');
    } else if (newPassword !== confirmPassword && confirmPassword.length > 0) {
      setError('The new password and confirmation must match.');
    } else {
      setError('');
    }
  }, [newPassword, confirmPassword]);

  // Function to check password quality
  const checkPasswordQuality = async (password) => {
    try {
      const response = await API.post('/user_auth/check-password/', { password });
      setScore(response.data.score || 0); // Ensure score defaults to 0 if undefined
    } catch (error) {
      console.error('Error checking password quality:', error);
      setScore(0); // Optionally, set a default score or handle the error state
    }
  };

  // Function to render the password strength meter
  const renderPasswordStrengthMeter = () => {
      const colors = ['#ff0000', '#ff4500', '#ffa500', '#9acd32', '#00ff00']; // Colors from red to green
      const barStyle = {
          width: newPassword.length > 0 ? `${Math.max(score * 25, 5)}%` : '0%',  // Only show bar if newPassword has content
          height: '100%',
          backgroundColor: colors[score],
          transition: 'width 0.3s ease-in-out, background-color 0.3s ease-in-out'  // Smoother transitions
      };
  
      return (
        <div style={{ width: '100%', height: '10px', backgroundColor: '#ddd' }}>
          <div style={barStyle}></div>
        </div>
      );
  };

  const saveChanges = (event) => {
    ReactGA.event({
      category: "password",
      action: "click-save-password",
      label: "click-save-password-settings_page",
    });

    event.preventDefault();
    if (error) {
      return;  // If there is an error on final submission attempt, prevent form submission
    }
    updatePassword();
  };

  // Function to update the password
  const updatePassword = async () => {
    setLoading(true);
    try {
      const payload = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };

      const response = await API.post('/user_auth/change-password/', payload);

      if (response.status === 200 || response.status === 201) { // Adjust based on backend response
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        setSuccess('Password changed successfully.');

        // Remove success message after 3 seconds
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } else {
        setError(response.data.message || 'An unknown error occurred');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else {
          setError(error.response.data.message || 'An error occurred while changing the password.');
        }
      } else {
        setError('Failed to communicate with the server.');
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="flex flex-col overflow-hidden bg-white sm:rounded-lg ml-16 md:ml-64 relative">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Change Password</h3>
      </div>
      <form onSubmit={saveChanges}>
        <table className="w-full text-sm text-left text-gray-500">
          <tbody>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Current Password</th>
              <td className="px-6 py-4">
                <input
                  type="password"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">New Password</th>
              <td className="px-6 py-4">
                <input
                  type="password"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                {renderPasswordStrengthMeter()}
              </td>
            </tr>
            <tr className="bg-white border-b border-gray-200">
              <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">Confirm New Password</th>
              <td className="px-6 py-4">
                <input
                  type="password"
                  className="border border-gray-300 rounded-md py-2 px-3 w-full"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </td>
            </tr>
            {error && (
              <tr className="bg-red-50 border-b border-gray-200">
                <td className="px-6 py-4" colSpan="2" style={{ color: 'red' }}>
                  {error}
                </td>
              </tr>
            )}
            {success && (
              <tr className="bg-green-50 border-b border-gray-200">
                <td className="px-6 py-4" colSpan="2" style={{ color: 'green' }}>
                  {success}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="px-6 py-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

