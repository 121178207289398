// src/Shared/PublicAPI.js

import axios from 'axios';

// A public client with minimal/no interceptors:
const PublicAPI = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
  },
  // No withCredentials, no token injection
  withCredentials: false,
});

// Optional: If you have a CSRF cookie from Django for POST, you might still add the CSRF token—but presumably this embed route is just a GET. 
// So we can skip interceptors entirely or add them if needed.

export default PublicAPI;
