import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import API from '../Shared/API'; // Import the centralized API utility

const AddAlertButton = ({ searchItem, local, state, setToast }) => {
  const [success, setSuccess] = useState(false);
  // const history = useHistory();

  const addKeywords = async () => {
    const keywords = searchItem ? 
      (searchItem.includes(",") ? searchItem.replace(/\s/g, "").split(",") : [searchItem]) 
      : [""];
      
    const requestBody = {
      keywords: keywords,
      locality: local.length ? local : [""],
      state: state.length ? state : [""],
      is_notifying: true,
      // channel: "",
      // frequency: "",
      // day: "",
      // time: "",
      // profile: null
    };

    try {
      const response = await API.post('/user_auth/alerts/', requestBody);

      if (response.status === 200|| response.status === 201) {
        console.log('Alert created successfully');
        setSuccess(true);
        setToast({ 
          notified: "new alert success", 
          state: true,
        });
      } else {
        const errorData = await response.json();
        console.error("New alert creation failed:", errorData);

        // Aggregate error messages
        const errorMessages = Object.values(errorData.details || {})
          .flat()
          .join(" ");

        setToast({ 
          notified: "new alert error", 
          message: errorMessages || "Failed to create alert.", 
          state: true,
        });
      }
    } catch (error) {
      console.error("Error creating alert:", error);
      setToast({ 
        notified: "new alert error", 
        message: "An unexpected error occurred while creating the alert.", 
        state: true,
      });
    }
  };

  useEffect(() => {
    setSuccess(false);
  }, [searchItem, local, state]);

  const hasKeywords = searchItem && searchItem.trim().length > 0;
  const hasLocation = local.length > 0 || state.length > 0;

  const getButtonText = () => {
    if (!hasKeywords && !hasLocation) {
      return "Select filters to add alert";
    }
    if (hasKeywords && !hasLocation) {
      return "Add Keyword Alert";
    }
    if (!hasKeywords && hasLocation) {
      return "Add Locality/Statewide Alert";
    }
    return "Add Locality + Keyword Alert";
  };

  return (
    <div className="mt-5">
      <button
        className={`mt-5 flex w-full items-center justify-center border border-transparent ${
          !hasKeywords && !hasLocation ? 'bg-blue-300' : 'bg-blue-500'
        } py-3 px-8 text-base font-medium text-white`}
        onClick={addKeywords}
        disabled={(!hasKeywords && !hasLocation) || success}
        data-tooltip="You can create alerts based on:
• Keywords (e.g., 'education', 'health')
• Localities/States
• Or both combined"
        title="You can create alerts based on:
• Keywords (e.g., 'education', 'health')
• Localities/States
• Or both combined"
      >
        {getButtonText()}
      </button>
    </div>
  );
}

export default AddAlertButton;
