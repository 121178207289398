/// api.js

import axios from 'axios';
import ReactGA from 'react-ga4';
import { getCookie } from "../Shared/getCookie";

// Create an Axios instance
const API = axios.create({
  baseURL: '/', // Adjust if your API is hosted on a different domain
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // If your backend uses cookies for CSRF
});

// Add a request interceptor to include the Authorization and CSRF tokens
API.interceptors.request.use(
  (config) => {
    // Retrieve JWT token from localStorage
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    // Retrieve CSRF token from cookies
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle token refresh or global errors
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Attempt to refresh the token
        const refreshResponse = await API.post('/api/token-refresh/', { token: localStorage.getItem('token') });
        const newAccessToken = refreshResponse.data.token;
        if (newAccessToken) {
          // Update the token in localStorage 
          localStorage.setItem('token', newAccessToken);
          // Update the Authorization header for future requests
          API.defaults.headers.common['Authorization'] = `Token ${newAccessToken}`;
          originalRequest.headers['Authorization'] = `Token ${newAccessToken}`;
          return API(originalRequest);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        ReactGA.event({
          category: "auth",
          action: "token-refresh-failure",
          label: "Token refresh failed, logging out user.",
        });
        // Logout user or redirect to login page
        localStorage.removeItem('token');
        window.location.href = '/login'; // Adjust the path as needed
      }
    }
    return Promise.reject(error);
  }
);

export default API;
