// src/Shared/Timezones.js

export const TIMEZONE_DISPLAY_NAMES = {
    "US/Eastern": "US Eastern",
    "US/Central": "US Central",
    "US/Mountain": "US Mountain",
    "US/Pacific": "US Pacific",
    "US/Arizona": "Arizona",
    "US/Alaska": "Alaska",
    "US/Hawaii": "Hawaii",
    "America/Puerto_Rico": "Puerto Rico",
    "Pacific/Guam": "Guam",
    // Add more mappings as needed
  };
  