// src/Embed/Embed.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PublicAPI from '../Shared/PublicAPI'; // use the new public client

function Embed() {
  const { embedId } = useParams();
  const [htmlContent, setHtmlContent] = useState("<p>Loading transcript...</p>");
  const [error, setError] = useState(null);

  useEffect(() => {
    // Use PublicAPI instead of API
    PublicAPI.get(`/embed/${embedId}`)
      .then((response) => {
        setHtmlContent(response.data ?? "<p>No content</p>");
      })
      .catch((err) => {
        console.error("Error fetching embed:", err);
        setError("Error loading embed content.");
      });
  }, [embedId]);

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}

export default Embed;
