import "../../index.css";

const findStateLocality = (objArray, arr) => {
  if (!arr || arr.length === 0 || (arr.length === 1 && arr[0] === "")) {
    return [];
  }
  
  let current = [];
  for (const value of arr) {
    // Find matching option
    const option = objArray.find(obj => obj.value === value);
    if (option) {
      current.push(option.label);
    } else {
      // If no matching option found, use the value as is
      current.push(value);
    }
  }
  return current;
};

// const localDate = (dateTime) => {
//   if (dateTime) {
//     var date = new Date(dateTime).toLocaleDateString();
//     return date;
//   }
//   return dateTime;
// }

const localDate = (utcDateTime, timeZone) => {
  if (!utcDateTime || !timeZone) return ""; // Handle missing values
  
  const date = new Date(utcDateTime);
  if (isNaN(date.getTime())) return "Invalid Date"; // Validate date
  
  return date.toLocaleString("en-US", { timeZone, dateStyle: "medium" });
};

const Row = (props) => {
  // stateOptions & localityOptions now come from the parent
  const { 
    keywords, 
    state, 
    locality, 
    localityOptions,
    stateOptions,
    // active,
    // setEdit,
    // updateAlert,
    // deleteAlert,
    // setModal,
    // etc...
  } = props;


  // Format keywords
  const keyword = keywords && keywords.length > 0 && keywords[0] !== ""
    ? keywords.join(", ")
    : "";

  // const keyword = props.keywords && props.keywords.length > 0 && props.keywords[0] !== "" 
  //   ? props.keywords.join(", ") 
  //   : "";

  // Convert array of state/locality string values into their labels
  const localityLabel = findStateLocality(localityOptions, locality)
    .map((loc, index) => <span key={index}>{loc}{index < locality.length - 1 ? ", " : ""}<br/></span>);

  const stateLabel = findStateLocality(stateOptions, state)
    .map((st, index) => <span key={index}>{st}{index < state.length - 1 ? ", " : ""}<br/></span>);

  const last_sent_time = localDate(props.last_sent,"US/Eastern");

  // const locality = findStateLocality(localityOptions, props.locality).join(", ");
  // const state = findStateLocality(stateOptions, props.state).join(", ");
  // for slack implementation
  // const channel = props.channel;
  // const frequency = props.frequency;
  const id = props.id;
  // const last_sent_time = localDate(props.last_sent)
  return props.active ? (
    // active alert row
    <tr className="bg-white border-b-2 border-gray-100 group hover:bg-gray-100">
      <td className="px-6 py-4 group/td w-1/12">
        <button
          className="items-center justify-center opacity-0 group-hover:opacity-100"
          onClick={() =>
            props.setEdit({
              alert: {
                id: props.id,
                keywords: props.keywords,
                locality: props.locality,
                state: props.state,
                // for slack implementation
                // channel: props.channel,
                sent_time: props.last_sent,
                // frequency: props.frequency,
                is_notifying: props.is_notifying,
                // day: props.day,
                // time: props.time,
              },
              edit: (prev) => !prev,
              type: "duplicate",
            })
          }
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.125 14.375V17.1875C13.125 17.705 12.705 18.125 12.1875 18.125H4.0625C3.81386 18.125 3.5754 18.0262 3.39959 17.8504C3.22377 17.6746 3.125 17.4361 3.125 17.1875V6.5625C3.125 6.045 3.545 5.625 4.0625 5.625H5.625C6.04381 5.62472 6.46192 5.65928 6.875 5.72834M13.125 14.375H15.9375C16.455 14.375 16.875 13.955 16.875 13.4375V9.375C16.875 5.65834 14.1725 2.57417 10.625 1.97834C10.2119 1.90928 9.79381 1.87472 9.375 1.875H7.8125C7.295 1.875 6.875 2.295 6.875 2.8125V5.72834M13.125 14.375H7.8125C7.56386 14.375 7.3254 14.2762 7.14959 14.1004C6.97377 13.9246 6.875 13.6861 6.875 13.4375V5.72834M16.875 11.25V9.6875C16.875 8.94158 16.5787 8.22621 16.0512 7.69876C15.5238 7.17132 14.8084 6.875 14.0625 6.875H12.8125C12.5639 6.875 12.3254 6.77623 12.1496 6.60041C11.9738 6.4246 11.875 6.18614 11.875 5.9375V4.6875C11.875 4.31816 11.8023 3.95243 11.6609 3.6112C11.5196 3.26998 11.3124 2.95993 11.0512 2.69876C10.7901 2.4376 10.48 2.23043 10.1388 2.08909C9.79757 1.94775 9.43184 1.875 9.0625 1.875H8.125"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6663 7.91663V12.0833M13.7497 9.99996H9.58301"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 w-1/6 break-words whitespace-normal">
        {/* <div className="w-full truncate"> */}
          {keyword}
          {/* </div> */}
      </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 w-1/6 break-words whitespace-normal">
        {/* <div className="w-full truncate"> */}
          {localityLabel}
          {/* </div> */}
      </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 w-1/6 break-words whitespace-normal">
        {/* <div className="truncate w-full"> */}
          {stateLabel}
          {/* </div> */}
      </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 w-1/6 break-words whitespace-normal">
        {/* <div className="truncate w-full"> */}
          {last_sent_time}
          {/* </div> */}
      </td>
      <td className="px-6 py-4 w-1/12">
        <button
          className="items-center justify-center"
          onClick={() =>
            props.setEdit({
              alert: {
                id: props.id,
                keywords: props.keywords,
                locality: props.locality,
                state: props.state,
                // for slack implementation
                // channel: props.channel,
                // frequency: props.frequency,
                is_notifying: props.is_notifying,
                // day: props.day,
                // time: props.time,
              },
              edit: (prev) => !prev,
              type: "edit",
            })
          }
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1094 1.89083C17.6992 1.48068 17.1428 1.25026 16.5627 1.25026C15.9826 1.25026 15.4263 1.48068 15.016 1.89083L14.0519 2.855L17.1452 5.94833L18.1094 4.98417C18.5195 4.57394 18.75 4.0176 18.75 3.4375C18.75 2.8574 18.5195 2.30106 18.1094 1.89083ZM16.261 6.8325L13.1677 3.73917L3.04271 13.8642C2.52842 14.3782 2.15036 15.0123 1.94271 15.7092L1.27605 17.9467C1.24386 18.0546 1.24146 18.1693 1.2691 18.2785C1.29674 18.3878 1.35339 18.4875 1.43306 18.5672C1.51273 18.6468 1.61245 18.7035 1.72168 18.7311C1.8309 18.7588 1.94557 18.7564 2.05355 18.7242L4.29105 18.0575C4.9879 17.8499 5.62201 17.4718 6.13605 16.9575L16.261 6.8325Z"
              fill="black"
            />
          </svg>
        </button>
      </td>
      <td className="px-6 py-4 w-1/12">
        <button
          className="items-center justify-center"
          onClick={() => {
            props.updateAlert(
              {
                ...props,
                is_notifying: !props.is_notifying,
              },
              true
            );
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.52985 2.47C3.38767 2.33752 3.19963 2.2654 3.00532 2.26882C2.81102 2.27225 2.62564 2.35096 2.48822 2.48838C2.35081 2.62579 2.2721 2.81118 2.26867 3.00548C2.26524 3.19978 2.33737 3.38782 2.46985 3.53L20.4698 21.53C20.5385 21.6037 20.6213 21.6628 20.7133 21.7038C20.8053 21.7448 20.9046 21.7668 21.0053 21.7686C21.106 21.7704 21.2061 21.7518 21.2994 21.7141C21.3928 21.6764 21.4777 21.6203 21.5489 21.549C21.6201 21.4778 21.6762 21.393 21.714 21.2996C21.7517 21.2062 21.7702 21.1062 21.7684 21.0055C21.7667 20.9048 21.7446 20.8055 21.7036 20.7135C21.6626 20.6215 21.6035 20.5387 21.5298 20.47L3.52985 2.47ZM20.5698 16.476C20.3468 16.558 20.1218 16.637 19.8958 16.714L7.31885 4.137C8.27278 3.21881 9.47569 2.60144 10.7778 2.36174C12.08 2.12204 13.4239 2.27059 14.6423 2.78891C15.8606 3.30723 16.8997 4.17242 17.63 5.2768C18.3604 6.38118 18.7498 7.67596 18.7498 9V9.75C18.7498 11.873 19.5498 13.807 20.8678 15.27C20.9499 15.361 21.0085 15.4707 21.0384 15.5896C21.0683 15.7084 21.0686 15.8328 21.0393 15.9518C21.01 16.0708 20.9519 16.1808 20.8703 16.2722C20.7886 16.3636 20.6848 16.4335 20.5698 16.476Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24973 8.99999C5.24973 8.81599 5.25673 8.63399 5.27173 8.45399L15.6557 18.838C15.4435 19.7592 14.8912 20.5663 14.1095 21.0978C13.3277 21.6293 12.3741 21.846 11.4394 21.7046C10.5047 21.5632 9.65785 21.074 9.0683 20.3351C8.47875 19.5961 8.18997 18.6617 8.25973 17.719C6.61138 17.4692 4.99272 17.0524 3.42873 16.475C3.31386 16.4326 3.21113 16.3627 3.12949 16.2714C3.04786 16.1802 2.98981 16.0703 2.96041 15.9515C2.93101 15.8326 2.93117 15.7083 2.96086 15.5896C2.99055 15.4708 3.04887 15.3611 3.13073 15.27C4.49754 13.7567 5.25281 11.7892 5.24973 9.74999V8.99999ZM9.75173 17.9C9.73894 18.2032 9.78761 18.5058 9.89481 18.7897C10.002 19.0736 10.1655 19.3329 10.3756 19.5519C10.5856 19.771 10.8377 19.9453 11.1168 20.0644C11.3959 20.1835 11.6963 20.2448 11.9997 20.2448C12.3032 20.2448 12.6035 20.1835 12.8826 20.0644C13.1618 19.9453 13.4139 19.771 13.6239 19.5519C13.8339 19.3329 13.9974 19.0736 14.1046 18.7897C14.2119 18.5058 14.2605 18.2032 14.2477 17.9C12.7521 18.0347 11.2474 18.0347 9.75173 17.9Z"
              fill="black"
            />
          </svg>
        </button>
      </td>
      <td className="px-6 py-4 w-1/12">
        <button
          className="items-center justify-center"
          onClick={() =>
            props.setModal({
              show: true,
              id: id,
            })
          }
        >
          <svg
            width="15"
            height="17"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.54793 16.1225L4.2838 18.9999H12.0366L14.6904 15.9498L14.6906 4.03651H1.54785L1.54793 16.1225ZM10.8665 6.43422C10.8665 6.23333 11.0293 6.07007 11.2302 6.07007C11.4315 6.07007 11.5946 6.23317 11.5946 6.43422V15.8129C11.5946 16.0145 11.4315 16.1774 11.2302 16.1774C11.0293 16.1774 10.8665 16.0145 10.8665 15.8129V6.43422ZM7.72977 6.43422C7.72977 6.23333 7.8926 6.07007 8.09351 6.07007C8.29482 6.07007 8.45791 6.23317 8.45791 6.43422V15.8129C8.45791 16.0145 8.2948 16.1774 8.09351 16.1774C7.89261 16.1774 7.72977 16.0145 7.72977 15.8129V6.43422ZM4.58369 6.43422C4.58369 6.23333 4.74652 6.07007 4.94785 6.07007C5.14917 6.07007 5.31201 6.23317 5.31201 6.43422V15.8129C5.31201 16.0145 5.14917 16.1774 4.94785 16.1774C4.74652 16.1774 4.58369 16.0145 4.58369 15.8129V6.43422Z"
              fill="black"
            />
            <path
              d="M12.0192 1.97283V0H4.15871V1.97298L0 1.97284V3.55858H16.269V1.97284L12.0192 1.97283ZM10.5623 1.97283H5.61562V1.45713H10.5623V1.97283Z"
              fill="black"
            />
          </svg>
        </button>
      </td>
    </tr>
  ) : (
    // inactive alert row
    <tr className="bg-white border-b-2 border-gray-100 group hover:bg-gray-100">
      <td className="px-6 py-4 sm:px-6 sm:py-4 text-gray-300 break-words whitespace-normal">
        {keyword}
        </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 text-gray-300 break-words whitespace-normal">
        {localityLabel}
        </td>
      <td className="px-6 py-4 sm:px-6 sm:py-4 text-gray-300 break-words whitespace-normal">
        {stateLabel}
        </td>
      {/*  for slack implementation */}
      {/* <td className="px-6 py-4 truncate text-gray-300">{channel}</td> */}
      {/* <td className="px-6 py-4 truncate text-gray-300">{frequency}</td> */}
      <td className="px-6 py-4">
        <button
          className="items-center justify-center"
          onClick={() =>
            props.updateAlert(
              {
                ...props,
                is_notifying: !props.is_notifying,
              },
              true
            )
          }
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24973 9C5.24973 7.20979 5.96089 5.4929 7.22676 4.22703C8.49263 2.96116 10.2095 2.25 11.9997 2.25C13.7899 2.25 15.5068 2.96116 16.7727 4.22703C18.0386 5.4929 18.7497 7.20979 18.7497 9V9.75C18.7497 11.873 19.5497 13.807 20.8677 15.27C20.9498 15.361 21.0084 15.4707 21.0383 15.5896C21.0682 15.7084 21.0685 15.8328 21.0391 15.9518C21.0098 16.0708 20.9518 16.1808 20.8702 16.2722C20.7885 16.3636 20.6857 16.4335 20.5707 16.476C19.0267 17.046 17.4107 17.466 15.7397 17.719C15.7774 18.2331 15.7086 18.7495 15.5377 19.2359C15.3668 19.7222 15.0974 20.1681 14.7464 20.5457C14.3955 20.9233 13.9704 21.2245 13.4978 21.4304C13.0252 21.6364 12.5152 21.7427 11.9997 21.7427C11.4842 21.7427 10.9742 21.6364 10.5016 21.4304C10.029 21.2245 9.60399 20.9233 9.25302 20.5457C8.90205 20.1681 8.6327 19.7222 8.4618 19.2359C8.29089 18.7495 8.22211 18.2331 8.25973 17.719C6.61138 17.4692 4.99272 17.0524 3.42873 16.475C3.31386 16.4326 3.21113 16.3627 3.12949 16.2715C3.04786 16.1802 2.98981 16.0703 2.96041 15.9515C2.93101 15.8326 2.93117 15.7084 2.96086 15.5896C2.99055 15.4708 3.04887 15.3611 3.13073 15.27C4.49754 13.7567 5.25281 11.7892 5.24973 9.75V9ZM9.75173 17.9C9.73894 18.2032 9.78761 18.5058 9.89481 18.7897C10.002 19.0736 10.1655 19.3329 10.3756 19.5519C10.5856 19.771 10.8377 19.9453 11.1168 20.0644C11.3959 20.1835 11.6963 20.2448 11.9997 20.2448C12.3032 20.2448 12.6035 20.1835 12.8826 20.0644C13.1618 19.9453 13.4139 19.771 13.6239 19.5519C13.8339 19.3329 13.9974 19.0736 14.1046 18.7897C14.2119 18.5058 14.2605 18.2032 14.2477 17.9C12.7521 18.0347 11.2474 18.0347 9.75173 17.9Z"
              fill="black"
            />
          </svg>
        </button>
      </td>
      <td className="px-6 py-4">
        <button
          className="items-center justify-center"
          onClick={() =>
            props.setModal({
              id: props.id,
              show: true,
            })
          }
        >
          <svg
            width="15"
            height="17"
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.54793 16.1225L4.2838 18.9999H12.0366L14.6904 15.9498L14.6906 4.03651H1.54785L1.54793 16.1225ZM10.8665 6.43422C10.8665 6.23333 11.0293 6.07007 11.2302 6.07007C11.4315 6.07007 11.5946 6.23317 11.5946 6.43422V15.8129C11.5946 16.0145 11.4315 16.1774 11.2302 16.1774C11.0293 16.1774 10.8665 16.0145 10.8665 15.8129V6.43422ZM7.72977 6.43422C7.72977 6.23333 7.8926 6.07007 8.09351 6.07007C8.29482 6.07007 8.45791 6.23317 8.45791 6.43422V15.8129C8.45791 16.0145 8.2948 16.1774 8.09351 16.1774C7.89261 16.1774 7.72977 16.0145 7.72977 15.8129V6.43422ZM4.58369 6.43422C4.58369 6.23333 4.74652 6.07007 4.94785 6.07007C5.14917 6.07007 5.31201 6.23317 5.31201 6.43422V15.8129C5.31201 16.0145 5.14917 16.1774 4.94785 16.1774C4.74652 16.1774 4.58369 16.0145 4.58369 15.8129V6.43422Z"
              fill="black"
            />
            <path
              d="M12.0192 1.97283V0H4.15871V1.97298L0 1.97284V3.55858H16.269V1.97284L12.0192 1.97283ZM10.5623 1.97283H5.61562V1.45713H10.5623V1.97283Z"
              fill="black"
            />
          </svg>
        </button>
      </td>
    </tr>
  );
};

export default Row;
