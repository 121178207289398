import { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import { TIMEZONE_DISPLAY_NAMES } from "../Shared/Timezones"; 
import API from '../Shared/API'; // Import the centralized API utility

const Profile = () => {
  const [editView, setEditView] = useState(false);
  const [error, setError] = useState(false);
  const [userId, setUserId] = useState(0);
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [slack, setSlack] = useState("");
  const [frequency, setFrequency] = useState("Daily");
  const [channels, setChannels] = useState("Email");
  const [editEmail, setEditEmail] = useState(email); 
  const [editSlack, setEditSlack] = useState(slack);
  const [editFrequency, setEditFrequency] = useState(frequency);
  const [editChannels, setEditChannels] = useState(channels);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [editDay, setEditDay] = useState("");
  const [selectedHours, setSelectedHours] = useState("08");
  const [selectedMinutes] = useState("00");
  const [selectedAMPM, setSelectedAMPM] = useState("AM");
  const [selectedTZ, setSelectedTZ] = useState("US/Eastern");
  const [editTime, setEditTime] = useState("08:00 AM");

  useEffect(() => {
    // Update editTime whenever hours, minutes, or AM/PM changes
    setEditTime(selectedHours + ":" + selectedMinutes + " " + selectedAMPM);
  }, [selectedHours, selectedMinutes, selectedAMPM]);

  const saveChanges = () => {
    ReactGA.event({
      category: "profile",
      action: "click-save-edit-alert",
      label: "click-save-edit-alert-settings_page",
    });

    if (!editChannels) {
      setError(true);
    } else {
      setError(false);
      updateProfile();
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await API.get('/user_auth/profiles/');
        const data = response.data;
        
        setUserId(data.user ? data.user.id : 0);
        setFName(data.user.first_name || "");
        setLName(data.user.last_name || "");
        setEmail(data.email_account || "");
        setSlack(data.slack_account || "");
        setFrequency(data.frequency || "Daily");
        setChannels(data.channel || "Email");
        setEditEmail(data.email_account || "");
        setEditSlack(data.slack_account || "");
        setEditFrequency(data.frequency || "Daily");
        setEditChannels(data.channel || "Email");
        setEditDay(data.day || "");
        setEditTime(data.time || "08:00 AM");
        setSelectedTZ(data.timezone || "US/Eastern");
        
        // Parse and set selectedHours and selectedAMPM
        if (data.time) {
          const [hourMinute, ampm] = data.time.split(" "); // ["9:00", "PM"]
          if (hourMinute && ampm) {
            const [hour] = hourMinute.split(":"); // Extracts "9"
            const formattedHour = hour.padStart(2, '0'); // Converts "9" to "09"
            
            setSelectedHours(formattedHour);
            setSelectedAMPM(ampm);
          }
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    
    fetchProfile();
  }, []);


  const updateProfile = async () => {
    setLoading(true);
    try {
      const payload = {
        email_account: editEmail,
        slack_account: editSlack,
        frequency: editFrequency,
        channel: editChannels,
        day: editDay,
        time: editTime,
        timezone: selectedTZ,
        // 'user' field removed since API.js handles authentication via headers
      };
      
      const response = await API.put('/user_auth/profiles/', payload);
      
      if (response.status === 200 || response.status === 201) { // Adjust based on backend response
        setLoading(false);
        setEmail(editEmail);
        setSlack(editSlack);
        setFrequency(editFrequency);
        setChannels(editChannels);
        setEditDay(editDay);
        setEditTime(editTime);
        setSelectedTZ(selectedTZ);
        setSuccess(true);
        setEditView(false);
        successRemove();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          window.location.href = "/login";
        } else {
          console.error("Failed to update profile:", error.response.data);
        }
      } else {
        console.error("Failed to update profile:", error);
      }
      setLoading(false);
    }
  };

  const successRemove = () => {
    setInterval(() => {
      setSuccess(false);
    }, 3000);
  };

  const handleClickEdit = () => {
    ReactGA.event({
      category: "profile",
      action: "click-edit-alert",
      label: "click-edit-alert-settings_page",
    });
  };

  const handleClickCancelEdit = () => {
    ReactGA.event({
      category: "profile",
      action: "click-cancel-edit-alert",
      label: "click-cancel-edit-alert-settings_page",
    });
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden bg-white sm:rounded-lg ml-16 md:ml-64 relative">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Profile Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Manage your personal details
          </p>
        </div>
        {!editView ? (
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              {/* Name Row */}
              <tr className="bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Name
                </th>
                <td className="px-6 py-4">{FName} {LName}</td>
                <td className="px-6 py-4"></td>
              </tr>
              {/* Email Address Row */}
              <tr className="bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Email Address
                </th>
                <td className="px-6 py-4">{email}</td>
                <td className="px-6 py-4"></td>
              </tr>
              {/*  for slack implementation */}
              {/* <tr className="bg-white border-b bg-white-200 border-gray-200">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Slack Account
                </th>
                <td className="px-6 py-4">{slack}</td>
                <td className="px-6 py-4"></td>
              </tr>
              <tr className="bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Channel you want to receive your alert
                </th>
                <td className="px-6 py-4">{channels}</td>
                <td className="px-6 py-4"></td>
              </tr> */}

              {/* Timezone Row */}
              <tr className="bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Time Zone
                </th>
                <td className="px-6 py-4">
                  {TIMEZONE_DISPLAY_NAMES[selectedTZ] || selectedTZ.replace('/', ' ')}
                </td>
                <td className="px-6 py-4"></td>
              </tr>

              {/* Frequency Row */}
              <tr className="bg-white bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Frequency
                </th>
                <td className="px-6 py-4">{frequency}</td>
                <td className="px-6 py-4"></td>
              </tr>

              {/* Conditional Day Row */}
              {frequency === "Weekly" && (
                <tr className=" bg-gray-50 bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Day for weekly alert
                  </th>
                  <td className="px-6 py-4">{editDay}</td>
                  <td className="px-6 py-4"></td>
                </tr>
              )}

              {/* Time Row */}
              <tr className="bg-white border-b bg-white-200 border-gray-100">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Time you want to receive your alert
                </th>
                <td className="px-6 py-4">{editTime}</td>
                <td className="px-6 py-4"></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <form>
            <table className="w-full text-sm text-left text-white-200 border-gray-200">
              <tbody>
                 {/* Name Row in Edit View */}
                 <tr className="bg-gray-50 border-b bg-white-200 border-gray-200">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Name
                  </th>
                  <td className="px-6 py-4">{FName} {LName}<br /><span class="text-gray-500">Please contact an admin to change your name</span></td>
                </tr>
                {/* Email Address Row in Edit View */}
                <tr className="bg-white border-b bg-white-200 border-gray-200">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Email Address
                  </th>
                  <td className="px-6 py-4">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={editEmail}
                      className="border border-gray-300 rounded-md py-2 px-3 w-full md:min-w-[150ch]"
                      onChange={(e) => {
                        setEditEmail(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                {/* <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Slack Account
                  </th>
                  <td className="px-6 py-4">
                    <input
                      type="text"
                      name="slack"
                      id="slack"
                      value={editSlack}
                      className="border border-gray-300 rounded-md py-2 px-3"
                      onChange={(e) => {
                        setEditSlack(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Set the channel you want to receive the alerts
                  </th>
                  <td className="px-6 py-4">
                    <select
                      id="channels"
                      value={editChannels}
                      onChange={(e) => setEditChannels(e.target.value)}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                    >
                      <option selected>Choose a channel</option>
                      <option value="Email">Email</option>
                      <option value="Slack">Slack</option>
                    </select>
                  </td>
                  <td className="px-6 py-4"></td>
                </tr> */}

                {/* Timezone Row in Edit View */}
                <tr className="bg-white border-b bg-white-200 border-gray-200">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Time Zone
                  </th>
                  <td className="px-6 py-4">
                  <select
                    name="tz"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                    value={selectedTZ}
                    onChange={(e) => setSelectedTZ(e.target.value)}
                  >
                    {Object.entries(TIMEZONE_DISPLAY_NAMES).map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>

                {/* Frequency Row in Edit View */}
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Frequency
                  </th>
                  <td className="px-6 py-4">
                    <select
                      name="frequency"
                      id="frequency"
                      value={editFrequency}
                      onChange={(e) => {
                        const selectedFrequency = e.target.value;
                        setEditFrequency(selectedFrequency);
                        if (selectedFrequency === "Weekly") {
                          // if day was null or empty, default to Monday
                          if (!editDay) setEditDay("Monday");
                        } else {
                          // For Daily, day is irrelevant. You can keep it empty or set to "".
                          setEditDay("");
                        }
                      }}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                    >
                      <option>Choose frequency</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                    </select>
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>

                {/* Conditional Day Row in Edit View */}
                {editFrequency === "Weekly" && (
                  <tr className="bg-white border-b bg-white-200 border-gray-100">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      Day for weekly alert
                    </th>
                    <td className="px-6 py-4">
                      <select
                        name="day"
                        id="day"
                        value={editDay}
                        onChange={(e) => setEditDay(e.target.value)}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 px-6"
                        placeholder="Select a day if you choose to receive the alert weekly"
                      >
                        <option value="" disabled>Select...</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                        <option value="Sunday">Sunday</option>
                      </select>
                    </td>
                    <td className="px-6 py-4"></td>
                  </tr>
                )}

                {/* Time Row in Edit View */}
                <tr className="bg-white border-b bg-white-200 border-gray-100">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Time you want to receive your alert
                  </th>
                  <td className="px-6 py-4">
                    <div className="px-5 py-2 w-40 bg-white border-gray-200 border-b">
                      <div className="flex">
                        <select
                          name="hours"
                          className="bg-transparent text-medium appearance-none outline-none mx-1"
                          value={selectedHours}
                          onChange={(e) => setSelectedHours(e.target.value)}
                        >
                          <option value="01">1:00</option>
                          <option value="02">2:00</option>
                          <option value="03">3:00</option>
                          <option value="04">4:00</option>
                          <option value="05">5:00</option>
                          <option value="06">6:00</option>
                          <option value="07">7:00</option>
                          <option value="08">8:00</option>
                          <option value="09">9:00</option>
                          <option value="10">10:00</option>
                          <option value="11">11:00</option>
                          <option value="12">12:00</option>
                        </select>
                        <select
                          name="ampm"
                          className="bg-transparent text-medium appearance-none outline-none mx-1"
                          onChange={(e) => setSelectedAMPM(e.target.value)}
                          value={selectedAMPM}
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>

                    </div>
                  </td>
                  <td className="px-6 py-4"></td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      </div>

      <div className="overflow-hidden bg-white sm:rounded-lg ml-64 relative">
        <div className=" overflow-hidden flex justify-left  px-4 py-5 sm:px-6">
          {!editView ? (
            <>
              <button
                className="bg-theme-blue items-center justify-center rounded-md border border-transparent py-2 px-6 bg-blue-500 text-base font-medium text-white"
                onClick={() => {
                  setEditView(true);
                  setLoading(false);
                  handleClickEdit();
                }}
              >
                Edit
              </button>
              {success && (
                <p className="items-center justify-center py-3 px-5">
                  Changes saved!
                </p>
              )}
            </>
          ) : (
            <>
              <button
                className="bg-theme-blue items-center justify-center rounded-md border border-transparent py-2 px-5 bg-blue-500 text-base font-medium text-white"
                onClick={saveChanges}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                className="items-center justify-center rounded-md border border-transparent py-2 px-5 bg-white-300 text-base font-medium text-black"
                onClick={() => {
                  setEditView(false);
                  handleClickCancelEdit();
                }}
              >
                Cancel
              </button>
              {error && (
                <p className="items-center justify-center py-3 px-5">
                  Please select a default channel
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
