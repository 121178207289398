import Select from "react-select";
import { useState, useEffect } from "react";
import Toast from "./Toast";
import API from '../Shared/API'; // Import the centralized API utility

const EditPanel = ({ editView, setEdit, updateAlert, createAlert }) => {
  // 1. Create local state to hold the fetched localities and states
  const [localityOptions, setLocalityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  function getSelectedOptions(valuesArray, allOptions) {
    return allOptions.filter((opt) => valuesArray.includes(opt.value));
  }

  // 2. Load the data once the component mounts
  useEffect(() => {
    fetchLocalities();
  }, []);

  // 3. Fetch localities and states from your API
  const fetchLocalities = async () => {
    try {
      const response = await API.get('/user_auth/user-localities/');

      if (response.status === 200|| response.status === 201) {
        console.log("Localities loaded normally.")
      } else {
        throw new Error(`Error: ${response.status}`);
      }
      // Our API already returns {value, label} objects
      setLocalityOptions(response.data.localities || []);
      setStateOptions(response.data.states || []);
    } catch (error) {
      console.error("Failed to fetch localities:", error);
      alert("Could not load localities. Please try again later.");
    }
  };

  const [newAlert, setNewAlert] = useState({
    id: editView.alert.id,
    keywords: editView.alert.keywords,
    locality: editView.alert.locality ?? [],  // Make sure it's an array
    state: editView.alert.state ?? [],        // Make sure it's an array
    // for slack implementation
    // channel: editView.alert.channel,
    // frequency: editView.alert.frequency,
    is_notifying: editView.alert.is_notifying,
    // day: editView.alert.day,
    // time: editView.alert.time,
  });
  const keywords = newAlert.keywords.join(",");
  // const timeNum = newAlert.time.match(/((1[0-2]|0?[1-9]):([0-5][0-9]))/)[2];
  // const ampm = newAlert.time.match(/[AaPp][Mm]/)[0];
  // const [time, setTime] = useState(timeNum);
  // const [ampmState, setAmpmState] = useState(ampm);

  const [isDuplicate, setIsDuplicate] = useState({
    state:false,
    notified: 'duplicate'
  });

  // To display the selected state and locality
  // const ensureStateVisibility = (arrOfValues, objArray) => {
  //   return objArray.filter(obj => arrOfValues.includes(obj.value));
  // };

  // Check if duplicate alert has been changed or not
  const checkDuplicate = () => {
    if (
      newAlert.keywords === editView.alert.keywords &&
      newAlert.locality === editView.alert.locality &&
      newAlert.state === editView.alert.state
      // newAlert.channel === editView.alert.channel &&
      // newAlert.frequency === editView.alert.frequency &&
      // newAlert.day === editView.alert.day &&
      // newAlert.time === editView.alert.time
    ) {
      setIsDuplicate({state:true});
    } else {
      setIsDuplicate({state:false});
      createAlert(newAlert);
    }
  };

  if (editView.edit)
    return (
      <>
        <aside
          id="drawer-contact"
          className="overscroll-contain top-14 right-0 bottom-0 z-40 p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-1/2 fixed shadow-lg"
          tabIndex="-1"
          aria-labelledby="drawer-contact-label"
        >
          <h5
            id="drawer-label"
            className="inline-flex items-center mb-6 text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
          >
            {editView.type === "edit" ? "Edit the alert" : "Add a new alert"}
          </h5>
          <button
            type="button"
            data-drawer-hide="drawer-contact"
            aria-controls="drawer-contact"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={() => {
              setEdit({ edit: false });
            }}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
          <div className="mb-2">
            <div className="mb-6">
              <label
                htmlFor="keywords"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Keywords
              </label>
              <input
                type="text"
                id="keywords"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={keywords}
                onChange={(e) =>
                  setNewAlert({
                    ...newAlert,
                    keywords: e.target.value.split(","),
                  })
                }
              />
              <p className="text-xs text-gray-500 dark:text-gray-400">
                For multiple keywords, separate them by comma
              </p>
            </div>
            <div className="mb-6">
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Locality
              </label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                // 'value' instead of 'defaultValue'
                value={getSelectedOptions(newAlert.locality, localityOptions)}
                onChange={(selectedLocalities) => {
                  setNewAlert({
                    ...newAlert,
                    // We'll store the 'value' strings in our newAlert
                    locality: selectedLocalities.map((loc) => loc.value),
                  });
                }}
                options={localityOptions}
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="state"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                State
              </label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                value={getSelectedOptions(newAlert.state, stateOptions)}
                onChange={(selectedStates) => {
                  setNewAlert({
                    ...newAlert,
                    state: selectedStates.map((st) => st.value),
                  });
                }}
                options={stateOptions}
              />
            </div>
            {/*  for slack implementation */}
            {/* <div className="mb-6">
              <label
                htmlFor="channel"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Channel
              </label>
              <select
                name="channel"
                id="channel"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={newAlert.channel}
                onChange={(e) => {
                  setNewAlert({ ...newAlert, channel: e.target.value });
                }}
              >
                <option value="Email">Email</option>
                <option value="Slack">Slack</option>
              </select>
            </div> */}
           
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 block"
              onClick={() => {
                editView.type === "edit" && updateAlert(newAlert, false);
                editView.type === "duplicate" && checkDuplicate();
              }}
            >
              {editView.type === "edit" ? "Save" : "Add"}
            </button>
            {isDuplicate.state && (
              <Toast notified="duplicate" setToast={setIsDuplicate} />
            )}
          </div>
        </aside>
      </>
    );
};

export default EditPanel;
