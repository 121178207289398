import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: this.getCurrentYear(props.timeZone || "America/Detroit"),
    };
  }

  getCurrentYear(timeZone) {
    const date = new Date();
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      timeZone: timeZone,
    }).format(date);
  }

  render() {
    const { currentYear } = this.state;

    return (
      <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-gray">
        <div className="grid grid-rows-2">
          <div>
            <a
              className="pr-5 font-regular font-medium"
              href="https://forms.office.com/r/ft1S8Q77Q5" onClick={this.handleContactFooter}>Request Access
            </a>
            <div className="mt-5 flex lg:float-right lg:mt-0">
              <a
                className="font-regular font-medium lg:px-5"
                href="https://michiganradio.secureallegiance.com/wuom/WebModule/Donate.aspx?P=SUSTAINER&PAGETYPE=PLG&CHECK=7xZmO7zqt1vzhRN6l0Ol+61gzMC6uhq5nDjkJobrCdg%3D"
              >
                <u>Donate</u>
              </a>
              <img
                className="px-5"
                src="https://storage.googleapis.com/minutes-resources/facebook-square.svg"
                alt="Facebook"
              />
              <img
                className="px-5"
                src="https://storage.googleapis.com/minutes-resources/linkedin.svg"
                alt="LinkedIn"
              />
              <img
                className="pl-5"
                src="https://storage.googleapis.com/minutes-resources/twitter.svg"
                alt="Twitter"
              />
            </div>
          </div>
          <div className="pt-5 font-regular">
            © 2019-{currentYear} Minutes. All rights reserved. Minutes is supported by Michigan Public, an NPR Member Station and a public media service of The University of Michigan.
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
