// LocationDateDisplay.jsx
import React from 'react';
import PropTypes from 'prop-types'; // Optional: For prop type validation
import { DateTime } from 'luxon';

const timezoneDictionary = {
  "US/Eastern": ["EST", "EDT"],
  "US/Central": ["CST", "CDT"],
  "US/Mountain": ["MST", "MDT"],
  "US/Pacific": ["PST", "PDT"],
  "US/Arizona": ["MST", null],
  "US/Alaska": ["Alaska Standard Time", "Alaska Daylight Time"],
  "US/Hawaii": ["Hawaii Standard Time", null],
  "America/Puerto_Rico": ["Atlantic Standard Time", "Atlantic Daylight Time"],
  "Pacific/Guam": ["Chamorro Standard Time", null],
  "Etc/GMT": ["GMT", null],
};


const getAPStyleTimezone = (dateTime, timeZone) => {
  try {
    const requestedTimezone = timezoneDictionary[timeZone];
    if (!requestedTimezone) {
      console.error(`Timezone "${timeZone}" not found in AP Style dictionary.`);
      return null;
    }

    const [standardAbbreviation, daylightAbbreviation] = requestedTimezone;

    if (!daylightAbbreviation) {
      return standardAbbreviation;
    }

    return dateTime.isInDST ? daylightAbbreviation : standardAbbreviation;
  } catch (error) {
    console.error(`Error in getAPStyleTimezone: ${error.message}`);
    return null;
  }
};


const LocationDateDisplay = ({ inputUTCDateISO, outputTimeZone, locale = 'en-US' }) => {
  if (!outputTimeZone) {
    return <span></span>; // Date is still loading
  }

  // Create a Date object from the input string
  const uploadDateUTC = DateTime.fromISO(inputUTCDateISO, { zone: 'utc' });

  if (!uploadDateUTC.isValid) {
    return <span>Invalid date</span>; 
  }

  const uploadDateLocal = uploadDateUTC.setZone(outputTimeZone);

  let formattedDate = uploadDateLocal.toFormat('LLLL d, yyyy h:mm a', { locale });

  formattedDate = formattedDate.replace(/\bAM\b/, 'a.m.').replace(/\bPM\b/, 'p.m.');

  // Get the AP style timezone abbreviation
  const timeZoneAbbreviation = getAPStyleTimezone(uploadDateLocal, outputTimeZone) || "";

  // Combine formatted date and timezone abbreviation
  const finalFormattedDate = `${formattedDate} ${timeZoneAbbreviation}`.trim();

  return <span>{finalFormattedDate}</span>;
};

// Optional: Define prop types
LocationDateDisplay.propTypes = {
  inputUTCDateISO: PropTypes.string.isRequired, // ISO string in UTC
  outputTimeZone: PropTypes.string.isRequired,  // Timezone identifier (e.g., "US/Pacific")
  locale: PropTypes.string,                     // Locale string (e.g., "en-US")
};

// Optional: Define default props if needed
LocationDateDisplay.defaultProps = {
  locale: 'en-US',
};

export default LocationDateDisplay;
