import Nav from "../Shared/Nav";
import "../../index.css";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import EditNotification from "./EditNotification";
import Groups from "./Groups";
import Sidebar from "./Sidebar";
import { useState } from "react";

const Notification = (props) => {
  const [page,setPage] = useState("profile");
  return (
    <div className="overscroll-contain">
      <header className="sticky z-50 w-full top-0 border-b-2 border-theme-gray">
        <Nav loggedIn={props.loggedIn} username={props.username}  timezone={props.timezone} />
      </header>
      <div className="flex flex-col md:flex-row">
        <Sidebar change={setPage} page={page}/>
          {page==="profile" && <Profile />}
          {page==="password" && <ChangePassword />}
          {page==="alerts" && <EditNotification />}
          {page==="groups" && <Groups />}
      </div>
        
    </div>
  );
};

export default Notification;
