import React, { Component }  from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import Nav from '../Shared/Nav';
import Search from '../Search/Search';
import Notification from '../Notification/Notification';
import ReactGA from 'react-ga4';
import API from '../Shared/API'; // Import the centralized API utility

// function getToken() {
//   const userToken = localStorage.getItem('token');

//   return userToken
// }

// async function checkToken(userToken) {
//   return fetch('/token-check/', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({ "token": userToken })
//   })
//   .then(data => data.json())
// }

// async function getUserProfile() {
//   return fetch("/user_auth/profiles/", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       "X-CSRFToken": getCookie("csrftoken"),
//       Authorization: "Token " + localStorage.getItem("auth-token"),
//     },
//   })
//   .then(data => data.json())
// }

// function checkLogin(response) {
//   let currentUser = null
//   let isLoggedIn = false

//   try {
//     currentUser = response.user.username
//     isLoggedIn = true

//     return [currentUser, isLoggedIn]
//   } catch (e) {
//     return [currentUser, isLoggedIn]
//   }
// }

// async function loginUser(credentials) {
//   return fetch('/token-auth/', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(credentials)
//   })
//   .then(data => data.json())
// }

// async function getAuthtoken(credentials) {
//   return fetch('/token/', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(credentials)
//   })
//   .then(data => data.json())
// }

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: this.props.loggedIn,
      password: '',
      username: this.props.username,
      userId: 0,
      failureMessage: '',
      timezone: this.props.timezone,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleContact = this.handleContact.bind(this);
  }

  async componentDidMount() {
    const userToken = localStorage.getItem('token');
    if (userToken) {
      try {
        const response = await API.post('/api/token-verify/', { token: userToken });
        if (response.status === 200 || response.status === 201) {
          // Token is valid
          const currentUser = response.data.user ? response.data.user.username : null;
          const currentUserId = response.data.user ? response.data.user.id : null;
          this.setState({
            failureMessage: '',
            loggedIn: true,
            username: currentUser,
            userId: currentUserId,
          });

          // Set user ID in Google Analytics
          ReactGA.set({ userId: currentUserId });

        } else {
          // Token invalid
          this.setState({ loggedIn: false });
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        this.setState({ loggedIn: false });
      }
    }
  }

  async handleLogin(e) {
    e.preventDefault();
    const username = this.state.username.toLowerCase();
    const password = this.state.password;

    try {
      // Obtain JWT token
      const loginResponse = await API.post('/api/token-auth/', { username, password });

      if (loginResponse.data.token) {
        // Store the token in localStorage
        localStorage.setItem('token', loginResponse.data.token);
        this.setState({ loggedIn: true, failureMessage: '' });

        // Record successful login
        const record = await API.post('/user_auth/login-success/');
        if (record.status === 200 || record.status === 201) {
          console.log("Successfully recorded login")
        } else {
          console.log("Unable to record successful login")
        }

        // Fetch user profile
        const profile = await API.get('/user_auth/profiles/');
        // const currentUser = profile.data.user ? profile.data.user.username : null;
        const currentUserId = profile.data.user ? profile.data.user.id : null;
        // const timezone = profile.data.user ? profile.data.timezone : null;
        // this.setState({ timezone: timezone});

        // Set user ID in Google Analytics
        ReactGA.set({ userId: currentUserId });

        // Send login event
        ReactGA.event({
          category: "login",
          action: "submit-login",
          label: "submit-login-success",
        });

      } else {
        this.setState({ failureMessage: 'Oops - something doesn\'t look right. Try again.' });

        // Send failed login event
        ReactGA.event({
          category: "login",
          action: "submit-login",
          label: "submit-login-failure",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      this.setState({ failureMessage: 'Invalid username or password. Please try again.' });

      // Send failed login event
      ReactGA.event({
        category: "login",
        action: "submit-login",
        label: "submit-login-failure",
      });
    }
  }

  handleChange(field, value) {
    if (field === "username") {
      value = value.toLowerCase();
    }
    this.setState({ [field]: value });
  }

  handleContact() {
    ReactGA.event({
      category: "contact",
      action: "click-mailto",
      label: "click-mailto-login_page",
    });
  }

  render() {
    ReactGA.send({
      hitType: "pageview",
      page:"/login",
      title:"login"
    });

    if(this.state.loggedIn) {
      return(
        <Router>
          <Redirect push to={this.props.path === '/login'? '/search': `${this.props.path}`} />
          <Route exact path="/search" component={() => <Search loggedIn={this.state.loggedIn} username={this.state.username} timezone={this.state.timezone}/>} />
          <Route exact path="/alerts" component={() => <Notification loggedIn={this.state.loggedIn} username={this.state.username} timezone={this.state.timezone}/>} />
        </Router>
      )
    }

    return(
      <div>
        <header className="sticky top-0">
          <Nav loggedIn={this.state.loggedIn} username={this.state.username} timezone={this.state.timezone}/>
        </header>
        <main>
          <div className="h-screen bg-theme-lightblue border-0 border-t border-theme-darkgray flex justify-center">
            <section className="mx-5 sm:mx-0 py-28">
              <form className="bg-white border border-black" onSubmit={this.handleLogin}>
                <h1 className="px-10 pt-10 font-regular font-medium text-theme-darkblue text-6xl">Log In</h1>
                <div className="px-10 py-5">
                  <label className="" htmlFor="username">
                    <p className="pt-5 pb-2 font-regular font-light text-theme-darkgray tracking-wide">EMAIL</p>
                  </label>
                  <input className="border border-black w-full py-2 px-3 leading-tight font-regular font-thin placeholder-s placeholder-black" id="username" type="text" placeholder="Email" onChange={e => this.handleChange('username', e.target.value)} required/>
                </div>
                <div className="px-10">
                  <label htmlFor="password">
                    <p className="py-2 font-regular font-light text-theme-darkgray tracking-wide">PASSWORD</p>
                  </label>
                  <input className="border border-black w-full py-2 px-3 leading-tight font-thin placeholder-s placeholder-black" id="password" type="password" placeholder="**********" onChange={e => this.handleChange('password', e.target.value)} required/>
                  <div className="mt-2 text-theme-darkblue">{ this.state.failureMessage }</div>
                </div>
                <div className="px-10 py-10">
                  <button className="px-7 py-3 bg-theme-yellow align-middle font-regular font-bold text-xl" type="submit">
                    Sign In
                  </button>
                </div>
                <div className="px-10 py-10 font-regular text-l">
                  Don't have a login? <a href="/contact"><u>Contact us</u></a> to set up beta access.
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    )
  }
}

export default Login;
