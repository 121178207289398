import React, { Component } from "react";
import Nav from '../Shared/Nav';
import Footer from '../Shared/Footer';
import '../../index.css';
import ReactGA from 'react-ga4';

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: this.props.loggedIn,
      username: this.props.username,
      userId: this.props.user_id
    }
    
    this.handleSignupFooter = this.handleSignupFooter.bind(this);
    this.handleContactFooter = this.handleContactFooter.bind(this);
  }

  handleSignupFooter() {
    ReactGA.event({
      category: "signup",
      action: "click-signup-button",
      label: "click-signup-button-footer-about",
    });
  }

  handleContactFooter() {
    ReactGA.event({
      category: "signup",
      action: "click-signup-button",
      label: "click-signup-button-footer-about",
    });
  }

  render() {
    ReactGA.send({
      hitType: "pageview",
      page:"/about",
      title:"about"
    });

    return (
      <div className="About">
        <header className="sticky top-0 z-50">
          <Nav loggedIn={this.props.loggedIn} username={this.props.username} user_id={this.props.user_id} />
        </header>
        <main className="relative">
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-20 bg-white">
            <h1 className="font-regular font-bold text-5xl text-theme-darkblue">How it works</h1>
            <p className="pt-10 font-regular text-3xl">
              Minutes was born out of a desire for more efficient and effective ways to sift through the mountains of information from local government meetings. Here’s how it works:
            </p>
          </section>
          <section className="px-5 pt-5 mb-14 sm:px-10 sm:mb-14 md:px-20 md:mb-28 bg-white">
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:mr-20">
                  <h1 className="font-regular font-medium text-black text-4xl">Minutes searches meeting videos...</h1>
                  <p className="font-regular text-xl pt-5">Minutes monitors websites of local governments and finds new meeting videos as they’re posted.</p>
                </div>
                <div className="w-2/3 mt-10 justify-center md:w-2/3 lg:w-1/2 md:min-w-xl md:mt-0">
                  <img src="https://storage.googleapis.com/minutes-resources/about_1.png" alt="..." />
                </div>
              </div>
              <div className="mt-20 flex flex-col md:flex-row items-center">
                <div className="hidden md:flex md:w-2/3 lg:w-1/2 md:min-w-xl md:mt-0">
                  <img src="https://storage.googleapis.com/minutes-resources/about_2.png" alt="..." />
                </div>
                <div className="md:ml-20">
                  <h1 className="font-regular font-medium text-black text-4xl">... and automatically generates meeting transcripts</h1>
                  <p className="font-regular text-xl pt-5">Minutes runs audio of the meeting through our transcription tool, and stores the transcript in a searchable database. Transcripts are organized by locality and date, and timestamped links to the original video remain in the database.</p>
                </div>
                <div className="w-2/3 mt-10 justify-center md:hidden">
                  <img src="https://storage.googleapis.com/minutes-resources/about_2.png" alt="..." />
                </div>
              </div>
              <div className="mt-20 flex flex-col md:flex-row items-center">
                <div className="md:mr-20">
                  <h1 className="font-regular font-medium text-black text-4xl">...so you can search transcripts and get email alerts to stay on top of the issues that matter to you</h1>
                  <p className="font-regular text-xl pt-5">
                    Reporters can search through the database to find topics they care about, and sign up for email alerts to be notified whenever new results show up in meetings.
                  </p>
                  <p className="font-regular text-xl pt-5">
                    Timestamped links to the original video are always preserved to allow reporters to confirm quotes and get additional context without having to sift through the entire meeting.</p>
                </div>
                <div className="w-2/3 mt-10 justify-center md:w-2/3 lg:w-1/2 md:min-w-xl md:mt-0">
                  <img src="https://storage.googleapis.com/minutes-resources/about_3.png" alt="..." />
                </div>
              </div>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-blue">
            <div className="flex">
              <div>
                <h1 className="font-regular font-medium text-4xl">
                  "Having access to the transcript is huge because listening through a city council meeting is such a slog."
                </h1>
                <h2 className="pt-10 font-regular text-xl">Lauren Talley, Morning Edition Producer at Michigan Public</h2>
              </div>
            </div>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-white">
          <h1 className="font-regular font-bold text-5xl text-theme-darkblue">Our story</h1>
            <p className="pt-10 font-regular text-xl">
              Minutes got its start in a local newsroom, when reporter <a href="https://www.michiganradio.org/people/dustin-dwyer"><u>Dustin Dwyer</u></a> and programmer <a href="https://www.michiganradio.org/people/brad-gowland"><u>Brad Gowland</u></a> dreamed up a better way to keep up with the dozens of local government meetings happening in Michigan every week. 
            </p>
            <p className="py-5 font-regular text-xl">
              The initial idea won funding through the Google News Iniative's <a href="https://newsinitiative.withgoogle.com/info/innovation-challenges/funding/north-america/"><u>Innovation Challenge</u></a> in 2019. With that funding, Minutes launched as a prototype service within the newsroom of Michigan Public, Michigan’s largest NPR news station. In 2022, the project won the National Association of Broadcasters <a href="https://nabpilot.org/pilot-announces-winning-teams-for-2022-innovation-challenge/"><u>PILOT Innovation Challenge</u></a> and was selected as one of five projects for the AP's <a href="https://www.ap.org/discover/Local-News-AI"><u>Local News AI</u></a> program. Through that collaboration, Minutes partnered with the <a href="https://knightlab.northwestern.edu/"><u>Northwestern University Knight Lab</u></a> to improve transcript quality and strengthen its backend process to be able to scale. Minutes has also received ongoing support from the University of Michigan, particularly from the office of <a href="https://innovationpartnerships.umich.edu/"><u>Innovation Partnerships</u></a> and the <a href="https://www.si.umich.edu/"><u>School of Information</u></a>.  
            </p>
            <p className="py-5 font-regular text-xl">
              Minutes would not be possible without the contributions of many individuals in these partnerships, including: 
            </p>
              <ul className="px-5 list-inside list-disc">
                <li className="font-regular text-l">Ernest Kung and Aimee Rinehart at the AP</li>
                <li className="font-regular text-l">Joe Germuska, Jeremy Gilbert and especially Scott Bradley at the Knight Lab</li>
                <li className="font-regular text-l">professor Mark Newman at UMSI and the students of SI699 for helping to build the Minutes email alert system</li>
                <li className="font-regular text-l">Scott Tenbrink, Emily Kutyla and the students at UMSI's Civic User Testing Group</li>
                <li className="font-regular text-l">Don Manfredi, MJ Cartwright and Ashwathi Ayer at Innovation Partnerships</li> 
                <li className="font-regular text-l">the entire team at Michigan Public, especially Vincent Duffy and Jodi Westrick who've supported Minutes from the beginning.</li>
              </ul>
            <p className="py-5 font-regular text-xl">
              Minutes currently tracks more than 100 city, county and school board meetings across the U.S., with thousands of transcripts in a database that grows every day. If you're interested in learning more, click on the Contact page and reach out! 
            </p>
          </section>
          <section className="px-5 py-10 sm:px-10 sm:py-14 md:px-20 md:py-28 bg-theme-yellow">
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-align-center font-regular font-bold text-4xl text-center" onClick={this.handleSignupFooter}>Sign up for access</h1>
              <h2 className="my-5 font-regular text-xl text-center">We are currently testing Minutes with a select group of journalists. Fill out our interest form to get started.</h2>
              <a href="https://forms.office.com/r/ft1S8Q77Q5" className="px-7 py-3 bg-theme-orange align-middle font-regular font-semibold text-2xl">Sign up</a>
            </div>
          </section>
          <Footer timeZone="America/Detroit" />
        </main>
      </div>
    );
  }
}

export default About;
